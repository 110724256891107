import TYPES from '@/types';
import { currencyFormat } from '@/vue-app/utils/currency';

import Vue from 'vue';
import { v4 } from 'uuid';

// Application
import GetInvestorProfileQuery
  from '@/modules/flagship/investor-profile/investor-profile/application/queries/get-investor-profile-query';
import CalculateCustomGoalMonthlyPaymentCalculationQuery
  from '@/modules/flagship/custom-goal-calculations/application/querys/calculate-custom-goal-monthly-payment-calculation-query';
import UpdateInvestorProfileCommand
  from '@/modules/flagship/investor-profile/investor-profile/application/commands/update-investor-profile-command';
import CalculateCustomGoalMaximumInitialAmountQuery
  from '@/modules/flagship/custom-investor-goal-calculator/application/queries/calculate-custom-goal-maximum-initial-amount-query';

// Domain
import {
  CustomInvestorGoalDto,
} from '@/modules/flagship/custom-investor-goal/domain/dtos/custom-investor-goal-dto';
import {
  InvestorProfileDto,
} from '@/modules/flagship/investor-profile/investor-profile/domain/dtos/investor-profile-dto';
import {
  CreateEmergencyFundInvestorGoalStateManager,
} from '@/modules/flagship/emergency-fund-investor-goal/domain/state/create-emergency-fund-investor-goal-state-manager';
import {
  GetInterestRateByFixedTimeService,
} from '@/modules/flagship/investor-goal/associated-product/domain/service/get-interest-rate-by-fixed-time-service';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import {
  maxLengthGoalNameRule, minValueRule, requiredRule, maxValueRule,
} from '@/vue-app/utils/form-rules';

export default class FlagshipGoalsWizardCustomizeGoalModel {
  @Inject(TYPES.GET_INVESTOR_PROFILE_QUERY)
  private readonly get_investor_profile_query!: GetInvestorProfileQuery;

  @Inject(TYPES.CALCULATE_CUSTOM_GOAL_MONTHLY_PAYMENT_CALCULATION_QUERY)
  private readonly calculate_monthly_payment_query!:
    CalculateCustomGoalMonthlyPaymentCalculationQuery;

  @Inject(TYPES.UPDATE_INVESTOR_PROFILE_COMMAND)
  private readonly update_investor_profile_command!: UpdateInvestorProfileCommand;

  @Inject(TYPES.CALCULATE_CUSTOM_GOAL_MAXIMUM_INITIAL_AMOUNT_QUERY)
  private readonly calculate_maximum_initial_amount!: CalculateCustomGoalMaximumInitialAmountQuery;

  @Inject(TYPES.GET_INTEREST_RATE_BY_FIXED_TYME_SERVICE)
  private readonly get_interest_rate_by_fixed_time!: GetInterestRateByFixedTimeService;

  @Inject(TYPES.CREATE_EMERGENCY_FUND_INVESTOR_GOAL_STATE_MANAGER)
  private readonly emergency_fund_investor_goal_state!: CreateEmergencyFundInvestorGoalStateManager;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  i18n_namespace = 'components.flagship.flagship-goals.flagship_goals_wizard_customize_goal';

  custom_investor_goal_id = v4();

  private custom_investor_goal_dto: CustomInvestorGoalDto = {
    id: v4(),
    goal_name: '',
    desired_amount: 0,
    fixed_time_adjusted: '',
    investor_goal: {
      investor_profile_id: '',
      initial_amount: 0,
      accumulated_amount: 0,
      monthly_required_amount: 0,
    },
    custom_goal_type: {
      id: '',
      icon_name: '',
      name: '',
      label: '',
      description: '',
      created_at: '',
      updated_at: '',
    },
  }

  investor_profile_id = '';

  goal_name = '';

  goal_total = '0';

  initial_amount = '0';

  time_to_reach_goal = 0;

  wants_years = false;

  monthly_payment_calculation = 0;

  accumulated_amount = 0;

  maximum_initial_amount = 100000000;

  interest_rate = 0;

  is_valid_form = false;

  input_rules = {
    other_goal_name: [requiredRule, (value: string) => maxLengthGoalNameRule(26, value)],
    goal_total: [requiredRule, (value: string) => minValueRule(value.replace(/[^0-9.-]/g, ''), '$1.00 MXN')],
    initial_amount: [
      requiredRule,
      (value: string) => minValueRule(value.replace(/[^0-9.-]/g, ''), '$0.00 MXN', 0),
      (value: string) => maxValueRule(
        value.replace(/[^0-9.-]/g, ''),
        currencyFormat(this.maximum_initial_amount),
        this.maximum_initial_amount,
      )],
  };

  timer?: NodeJS.Timer;

  investor_goal_state = this.emergency_fund_investor_goal_state.state;

  private investor_profile_dto: InvestorProfileDto = {
    id: this.investor_profile_id,
    custom_goals: {},
    custom_investor_goal: {
      goal_name: '',
      desired_amount: 0,
      goal_type: {
        id: '',
        name: '',
        label: '',
        description: '',
        icon_name: '',
        created_at: '',
        updated_at: '',
      },
      initial_amount: 0,
      monthly_required_amount: 0,
      fixed_time_adjusted: 0,
      accumulated_amount: 0,
    },
  };

  private view!: Vue;

  constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  get btn_years_text() {
    return this.translate(this.wants_years ? 'caption_2' : 'caption');
  }

  get monthly_payment() {
    return Math.trunc(this.monthly_payment_calculation).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  get userWantsYears() {
    return this.wants_years ? 20 : 11;
  }

  get is_continue_btn_disabled() {
    return !(this.goal_name !== ''
      && this.goal_total !== '$0 MXN' && this.is_valid_form)
      || !(this.goal_name.length < 26) || this.investor_goal_state.is_loading;
  }

  get goal_total_value() {
    return this.goal_total.toString().replace(/[^0-9.]/g, '');
  }

  get goal_initial_amount() {
    return this.initial_amount.toString().replace(/[^0-9.]/g, '');
  }

  get fixed_time_adjusted() {
    return this.wants_years
      ? this.time_to_reach_goal * 12
      : this.time_to_reach_goal;
  }

  setInterestRate = (wants_years: boolean) => {
    this.interest_rate = this.get_interest_rate_by_fixed_time.getInterestRate(wants_years);
  }

  toggleWantsYears = async () => {
    this.wants_years = !this.wants_years;
    this.setInterestRate(this.wants_years);
    await this.calculateMonthlyPayment();
  }

  delay = () => {
    this.investor_goal_state.is_loading = true;
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
    this.timer = setTimeout(async () => {
      await this.calculateMonthlyPayment();
    }, 1000);
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  async loadInvestorProfileData() {
    try {
      const { custom_goals, id } = await this.get_investor_profile_query.execute(false);
      const [custom_goal] = custom_goals.values;
      custom_goal.label! = custom_goal.label;
      this.custom_investor_goal_dto.custom_goal_type = custom_goal;
      this.goal_name = custom_goal.label;
      this.investor_profile_id = id;
      await this.getCustomInvestorGoal();
    } catch {
      this.message_notifier.showErrorNotification(`${this.translate('errors.load_investor_profile')}`);
    }
  }

  getCustomInvestorGoal = async () => {
    try {
      this.investor_goal_state.custom.id = this.custom_investor_goal_id;
      const { fixed_time_adjusted } = this.investor_goal_state.custom;
      this.initial_amount = String(this.investor_goal_state.custom.initial_amount);
      this.goal_total = String(this.investor_goal_state.custom.desired_amount);
      this.wants_years = fixed_time_adjusted >= 11;
      this.time_to_reach_goal = fixed_time_adjusted >= 11
        ? fixed_time_adjusted / 12 : fixed_time_adjusted;
      await this.calculateMonthlyPayment();
      return true;
    } catch {
      return false;
    }
  }

  async initialize() {
    this.investor_goal_state.is_loading = true;
    this.setInterestRate(false);
    await this.loadInvestorProfileData();
    this.investor_goal_state.is_loading = false;
  }

  calculateMaximumInitialAmount = async () => {
    try {
      this.investor_goal_state.is_loading = true;
      if (parseFloat(this.goal_total_value) > 0 && this.fixed_time_adjusted > 0
        && this.interest_rate > 0) {
        const desired_amount = this.goal_total_value;
        const payload = {
          desired_amount,
          monthly_required_amount: '0.00',
          fixed_time_adjusted: this.fixed_time_adjusted,
          interest_rate: this.interest_rate,
        };
        const {
          maximum_initial_amount,
        } = await this.calculate_maximum_initial_amount.execute(payload);
        this.maximum_initial_amount = Math.floor(parseFloat(maximum_initial_amount));
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        this.view.$refs.form.validate();
      }
    } catch {
      this.message_notifier.showErrorNotification(`${this.translate('errors.calculate_maximum_initial_amount')}`);
    } finally {
      this.investor_goal_state.is_loading = false;
    }
  }

  calculateMonthlyPayment = async () => {
    try {
      this.investor_goal_state.is_loading = true;
      await this.calculateMaximumInitialAmount();
      const desired_amount = this.goal_total_value;
      const payload = {
        desired_amount,
        initial_amount: this.goal_initial_amount,
        fixed_time_adjusted: this.fixed_time_adjusted,
      };
      const {
        custom_investor_goal_calculation,
      } = await this.calculate_monthly_payment_query.execute(payload);
      if (custom_investor_goal_calculation.monthly_required_amount >= 0) {
        this.monthly_payment_calculation = custom_investor_goal_calculation.monthly_required_amount;
        this.accumulated_amount = custom_investor_goal_calculation.accumulated_amount;
      }
    } catch {
      this.message_notifier.showErrorNotification(`${this.translate('errors.calculate_monthly_payment')}`);
    } finally {
      this.investor_goal_state.is_loading = false;
    }
  }

  createGoal = async () => {
    try {
      this.investor_goal_state.is_loading = true;
      this.investor_goal_state.custom.goal_name = this.goal_name;
      this.investor_goal_state.custom.desired_amount = Number(this.goal_total_value);
      this.investor_goal_state.custom.fixed_time_adjusted = this.fixed_time_adjusted;
      this.investor_goal_state.custom.accumulated_amount = this.accumulated_amount;
      this.investor_goal_state.custom.monthly_required_amount = this.monthly_payment_calculation;
      this.investor_goal_state.custom.initial_amount = Number(this.goal_initial_amount);
      if (this.custom_investor_goal_dto.custom_goal_type) {
        this.investor_goal_state.custom.goal_type = this.custom_investor_goal_dto.custom_goal_type;
      }
      this.investor_goal_state.custom.goal_type.label = this.goal_name;
      if (await this.updateInvestorProfile()) {
        this.view.$emit('nextStep');
      }
    } catch {
      this.message_notifier.showErrorNotification(`${this.translate('errors.create_custom_goal')}`);
    } finally {
      this.investor_goal_state.is_loading = false;
    }
  }

  updateInvestorProfile = async () => {
    try {
      this.investor_profile_dto.id = this.investor_profile_id;
      this.investor_profile_dto.custom_goals = {
        values: [
          this.custom_investor_goal_dto.custom_goal_type,
        ],
      };
      this.investor_profile_dto.custom_investor_goal = {
        goal_name: this.investor_goal_state.custom.goal_name,
        desired_amount: this.investor_goal_state.custom.desired_amount,
        initial_amount: this.investor_goal_state.custom.initial_amount,
        fixed_time_adjusted: this.investor_goal_state.custom.fixed_time_adjusted,
        monthly_required_amount: this.investor_goal_state.custom.monthly_required_amount,
        accumulated_amount: this.investor_goal_state.custom.accumulated_amount,
        goal_type: this.investor_goal_state.custom.goal_type,
      };
      await this.update_investor_profile_command.execute(this.investor_profile_dto);
      return true;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.update_investor_profile'));
      return false;
    }
  };
}
